<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";
import { required, requiredIf } from "vuelidate/lib/validators";
import UploadFile from "@/components/widgets/contracts-upload-file";

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout, Stat, UploadFile },
  computed: {
    requiredLegal() {
      if (this.client.type == "legal") {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      alert: {
        type: "",
        message: "",
      },

      statData: null,

      step: 1,

      loading: {
        client: false,
        send: false,
      },

      contract: {
        code: null,
        status: null,
        uc: null,
      },

      client: {
        type: "",
        name: "",
        birthdate: "",
        cpf: "",
        rg_number: "",
        rg_issuer: "",
        nationality: "",
        marital_status: "",
        occupation: "",
        legal_name: "",
        legal_number: "",
        legal_opening: "",

        zipcode: "",
        address: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",
        cellphone: "",
        email: "",
      },

      invoice: {
        uc: "",
      },

      files: null,
    };
  },
  validations: {
    client: {
      type: { required },

      name: { required },
      birthdate: { required },
      cpf: { required },
      rg_number: { required },
      rg_issuer: { required },
      nationality: { required },
      marital_status: { required },
      occupation: {},

      legal_name: {
        required: requiredIf(function () {
          return this.requiredLegal;
        }),
      },
      legal_number: {
        required: requiredIf(function () {
          return this.requiredLegal;
        }),
      },
      legal_opening: {
        required: requiredIf(function () {
          return this.requiredLegal;
        }),
      },

      zipcode: { required },
      address: { required },
      number: { required },
      complement: {},
      district: { required },
      city: { required },
      state: { required },

      cellphone: { required },
      email: { required },
    },
    invoice: {
      uc: { required },
    }
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0);
    },
    searchCep() {
      if (this.zipcode.length == 9) {
        api.get("cep/" + this.zipcode).then((response) => {
          if (response.data.city !== "") {
            if (response.data.address) {
              this.address = response.data.address;
            }
            if (response.data.district) {
              this.district = response.data.district;
            }
            this.city = response.data.city;
            this.state = response.data.state;
          }
        });
      }
    },
    getContract() {
      api
        .get("energy/contracts/" + this.$route.params.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.contract.code = response.data.contract.code;
            this.contract.status = response.data.contract.status;
            this.contract.uc = response.data.contract.uc;

            this.client = response.data.contract.client;

            this.statData = [
              {
                title: "Código",
                value: response.data.contract.code,
              },
              {
                title: "Status",
                value: response.data.contract.message,
              },
            ];
          }
        })
        .catch((error) => {
          if (error) {
            this.$router.push("/energy/contracts");
          }
        });
    },
    nextToFiles() {
      this.$v.client.$touch();
      if (this.$v.client.$invalid) {
        return;
      } else {
        this.loading.client = true;

        api
          .post("energy/clients/" + this.client.id, {
            type: this.client.type,
            name: this.client.name,
            birthdate: this.client.birthdate,
            cpf: this.client.cpf,
            rg_number: this.client.rg_number,
            rg_issuer: this.client.rg_issuer,
            nationality: this.client.nationality,
            marital_status: this.client.marital_status,
            occupation: this.client.occupation,
            legal_name: this.client.legal_name,
            legal_number: this.client.legal_number,
            legal_opening: this.client.legal_opening,
            zipcode: this.client.zipcode,
            address: this.client.address,
            number: this.client.number,
            complement: this.client.complement,
            district: this.client.district,
            city: this.client.city,
            state: this.client.state,
            cellphone: this.client.cellphone,
            email: this.client.email,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.getFiles();
              this.toTop();
              this.step = 2;
            } else {
              this.toTop();
              this.alert.type = "alert-danger";
              this.alert.message = response.data.message;
            }

            this.loading.client = false;
          });
      }
    },
    getFiles() {
      api
        .get("energy/contracts/files/" + this.$route.params.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.files = response.data.list;
          }
        });
    },
    sendContract() {
      this.$v.invoice.$touch();
      if (this.$v.invoice.$invalid) {
        return;
      } else {
        this.loading.send = true;

        api
          .post("energy/contracts/send", {
            id: this.$route.params.id,
            uc: this.invoice.uc
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$noty.success(response.data.message)
              this.getContract()
            } else {
              this.$noty.error(response.data.message)
            }

            this.loading.send = false;
          })
      }
    },
  },
  mounted() {
    this.getContract();
    this.getFiles();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Contrato</h4>
          <div class="page-title-right"></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="client && client.type" class="p-3">
              <template v-if="contract.status == 'pending'">
                <template v-if="step == 1">
                  <b-form-group :label="t('Tipo de Pessoa')">
                    <select v-model="client.type" class="custom-select mb-1">
                      <option value="natural">Pessoa Física</option>
                      <option value="legal">Pessoa Jurídica</option>
                    </select>
                    <div
                      v-if="$v.client.type.$error"
                      class="text-danger font-size-12 mt-1"
                    >
                      <span v-if="!$v.client.type.required">
                        {{ t("O tipo de pessoa é obrigatório.") }}
                      </span>
                    </div>
                  </b-form-group>

                  <template v-if="client.type == 'natural'">
                    <div class="divisor mt-5 mb-3">
                      <span>Dados Pessoais</span>
                    </div>

                    <b-form-group :label="t('Nome Completo')">
                      <b-form-input
                        v-model="client.name"
                        type="text"
                        :class="{ 'is-invalid': $v.client.name.$error }"
                      ></b-form-input>
                      <div
                        v-if="$v.client.name.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.client.name.required">
                          O nome é obrigatório.
                        </span>
                      </div>
                    </b-form-group>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group :label="t('Data de nascimento')">
                          <b-form-input
                            v-model="client.birthdate"
                            type="text"
                            :class="{ 'is-invalid': $v.client.birthdate.$error }"
                            v-mask="'##/##/####'"
                          ></b-form-input>
                          <div
                            v-if="$v.client.birthdate.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.birthdate.required">
                              A data de nascimento é obrigatória.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group :label="t('CPF')">
                          <b-form-input
                            v-model="client.cpf"
                            type="text"
                            :class="{ 'is-invalid': $v.client.cpf.$error }"
                            v-mask="'###.###.###-##'"
                          ></b-form-input>
                          <div
                            v-if="$v.client.cpf.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span
                              v-if="!$v.client.cpf.required"
                              class="text-danger font-size-12 mt-1"
                            >
                              O CPF é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group :label="t('RG')">
                          <b-form-input
                            v-model="client.rg_number"
                            type="text"
                            :class="{ 'is-invalid': $v.client.rg_number.$error }"
                          ></b-form-input>
                          <div
                            v-if="$v.client.rg_number.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.rg_number.required">
                              O RG é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group :label="t('Órgão Emissor')">
                          <b-form-input
                            v-model="client.rg_issuer"
                            type="text"
                            :class="{ 'is-invalid': $v.client.rg_issuer.$error }"
                          ></b-form-input>
                          <div
                            v-if="$v.client.rg_issuer.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span
                              v-if="!$v.client.rg_issuer.required"
                              class="text-danger font-size-12 mt-1"
                            >
                              O órgão emissor é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group :label="t('Nacionalidade')">
                          <b-form-input
                            v-model="client.nationality"
                            type="text"
                            :class="{
                              'is-invalid': $v.client.nationality.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="$v.client.nationality.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.nationality.required">
                              A nacionalidade é obrigatória.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group :label="t('Estado Civil')">
                          <select
                            v-model="client.marital_status"
                            class="custom-select mb-1"
                          >
                            <option value="">–</option>
                            <option value="Solteiro(a)">Solteiro(a)</option>
                            <option value="Casado(a)">Casado(a)</option>
                            <option value="Separado(a)">Separado(a)</option>
                            <option value="Divorciado(a)">Divorciado(a)</option>
                            <option value="Viúvo(a)">Viúvo(a)</option>
                          </select>
                          <div
                            v-if="$v.client.marital_status.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span
                              v-if="!$v.client.marital_status.required"
                              class="text-danger font-size-12 mt-1"
                            >
                              O estado civil é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group :label="t('Celular')">
                          <b-form-input
                            v-model="client.cellphone"
                            type="text"
                            :class="{ 'is-invalid': $v.client.cellphone.$error }"
                            v-mask="'(##) #####-####'"
                          ></b-form-input>
                          <div
                            v-if="$v.client.cellphone.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.cellphone.required">
                              O celular é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group :label="t('E-mail')">
                          <b-form-input
                            v-model="client.email"
                            type="text"
                            :class="{ 'is-invalid': $v.client.email.$error }"
                          ></b-form-input>
                          <div
                            v-if="$v.client.email.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span
                              v-if="!$v.client.email.required"
                              class="text-danger font-size-12 mt-1"
                            >
                              O e-mail é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="divisor mt-5 mb-3">
                      <span>Endereço</span>
                    </div>

                    <b-form-group label="CEP">
                      <b-form-input
                        v-on:blur="searchCep()"
                        v-model="client.zipcode"
                        type="text"
                        :class="{ 'is-invalid': $v.client.zipcode.$error }"
                        autocomplete="off"
                        v-mask="'#####-###'"
                      ></b-form-input>
                      <div
                        v-if="$v.client.zipcode.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.client.zipcode.required"
                          >O CEP é obrigatório.</span
                        >
                        <span v-if="!$v.client.zipcode.invalid"
                          >O CEP informado é invalido.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group label="Endereço">
                      <b-form-input
                        v-model="client.address"
                        type="text"
                        :class="{ 'is-invalid': $v.client.address.$error }"
                        autocomplete="off"
                      ></b-form-input>
                      <div
                        v-if="$v.client.address.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.client.address.required"
                          >O endereço é obrigatório.</span
                        >
                      </div>
                    </b-form-group>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group label="Número">
                          <b-form-input
                            v-model="client.number"
                            type="text"
                            :class="{ 'is-invalid': $v.client.number.$error }"
                            autocomplete="off"
                          ></b-form-input>
                          <div
                            v-if="$v.client.number.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.number.required"
                              >O número é obrigatório.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group label="Complemento">
                          <b-form-input
                            v-model="client.complement"
                            type="text"
                            :class="{ 'is-invalid': $v.client.complement.$error }"
                            autocomplete="off"
                          ></b-form-input>
                          <div
                            v-if="$v.client.complement.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.complement.required"
                              >O complemento é obrigatório.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <b-form-group label="Bairro">
                      <b-form-input
                        v-model="client.district"
                        type="text"
                        :class="{ 'is-invalid': $v.client.district.$error }"
                        autocomplete="off"
                      ></b-form-input>
                      <div
                        v-if="$v.client.district.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.client.district.required"
                          >O bairro é obrigatório.</span
                        >
                      </div>
                    </b-form-group>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group label="Cidade">
                          <b-form-input
                            class="bg-soft-info"
                            v-model="client.city"
                            type="text"
                            :class="{ 'is-invalid': $v.client.city.$error }"
                            autocomplete="off"
                            readonly="readonly"
                          ></b-form-input>
                          <div
                            v-if="$v.client.city.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.city.required"
                              >A cidade é obrigatória.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group label="Estado">
                          <b-form-input
                            class="bg-soft-info"
                            v-model="client.state"
                            type="text"
                            :class="{ 'is-invalid': $v.client.state.$error }"
                            autocomplete="off"
                            readonly="readonly"
                            v-mask="'XX'"
                          ></b-form-input>
                          <div
                            v-if="$v.client.state.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.state.required"
                              >O estado é obrigatório.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                  </template>

                  <template v-else-if="client.type == 'legal'">
                    <div class="divisor mt-5 mb-3">
                      <span>Dados da Empresa</span>
                    </div>

                    <b-form-group :label="t('Razão Social')">
                      <b-form-input
                        v-model="client.legal_name"
                        type="text"
                        :class="{ 'is-invalid': $v.client.legal_name.$error }"
                      ></b-form-input>
                      <div
                        v-if="$v.client.legal_name.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.client.legal_name.required">
                          A razão social é obrigatória.
                        </span>
                      </div>
                    </b-form-group>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group :label="t('CNPJ')">
                          <b-form-input
                            v-model="client.legal_number"
                            type="text"
                            :class="{
                              'is-invalid': $v.client.legal_number.$error,
                            }"
                            v-mask="'##.###.###/####-##'"
                          ></b-form-input>
                          <div
                            v-if="$v.client.legal_number.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.legal_number.required">
                              O CNPJ é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group :label="t('Data de Abertura')">
                          <b-form-input
                            v-model="client.legal_opening"
                            type="text"
                            :class="{
                              'is-invalid': $v.client.legal_opening.$error,
                            }"
                            v-mask="'##/##/####'"
                          ></b-form-input>
                          <div
                            v-if="$v.client.legal_opening.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.legal_opening.required">
                              A data de abertura obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="divisor mt-5 mb-3">
                      <span>Dados do Representante</span>
                    </div>

                    <b-form-group :label="t('Nome Completo')">
                      <b-form-input
                        v-model="client.name"
                        type="text"
                        :class="{ 'is-invalid': $v.client.name.$error }"
                      ></b-form-input>
                      <div
                        v-if="$v.client.name.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.client.name.required">
                          O nome é obrigatório.
                        </span>
                      </div>
                    </b-form-group>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group :label="t('Data de nascimento')">
                          <b-form-input
                            v-model="client.birthdate"
                            type="text"
                            :class="{ 'is-invalid': $v.client.birthdate.$error }"
                            v-mask="'##/##/####'"
                          ></b-form-input>
                          <div
                            v-if="$v.client.birthdate.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.birthdate.required">
                              A data de nascimento é obrigatória.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group :label="t('CPF')">
                          <b-form-input
                            v-model="client.cpf"
                            type="text"
                            :class="{ 'is-invalid': $v.client.cpf.$error }"
                            v-mask="'###.###.###-##'"
                          ></b-form-input>
                          <div
                            v-if="$v.client.cpf.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span
                              v-if="!$v.client.cpf.required"
                              class="text-danger font-size-12 mt-1"
                            >
                              O CPF é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group :label="t('RG')">
                          <b-form-input
                            v-model="client.rg_number"
                            type="text"
                            :class="{ 'is-invalid': $v.client.rg_number.$error }"
                          ></b-form-input>
                          <div
                            v-if="$v.client.rg_number.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.rg_number.required">
                              O RG é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group :label="t('Órgão Emissor')">
                          <b-form-input
                            v-model="client.rg_issuer"
                            type="text"
                            :class="{ 'is-invalid': $v.client.rg_issuer.$error }"
                          ></b-form-input>
                          <div
                            v-if="$v.client.rg_issuer.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span
                              v-if="!$v.client.rg_issuer.required"
                              class="text-danger font-size-12 mt-1"
                            >
                              O órgão emissor é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group :label="t('Nacionalidade')">
                          <b-form-input
                            v-model="client.nationality"
                            type="text"
                            :class="{
                              'is-invalid': $v.client.nationality.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="$v.client.nationality.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.nationality.required">
                              A nacionalidade é obrigatória.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group :label="t('Estado Civil')">
                          <select
                            v-model="client.marital_status"
                            class="custom-select mb-1"
                          >
                            <option value="">–</option>
                            <option value="Solteiro(a)">Solteiro(a)</option>
                            <option value="Casado(a)">Casado(a)</option>
                            <option value="Separado(a)">Separado(a)</option>
                            <option value="Divorciado(a)">Divorciado(a)</option>
                            <option value="Viúvo(a)">Viúvo(a)</option>
                          </select>
                          <div
                            v-if="$v.client.marital_status.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span
                              v-if="!$v.client.marital_status.required"
                              class="text-danger font-size-12 mt-1"
                            >
                              O estado civil é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group :label="t('Celular')">
                          <b-form-input
                            v-model="client.cellphone"
                            type="text"
                            :class="{ 'is-invalid': $v.client.cellphone.$error }"
                            v-mask="'(##) #####-####'"
                          ></b-form-input>
                          <div
                            v-if="$v.client.cellphone.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.cellphone.required">
                              O celular é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group :label="t('E-mail')">
                          <b-form-input
                            v-model="client.email"
                            type="text"
                            :class="{ 'is-invalid': $v.client.email.$error }"
                          ></b-form-input>
                          <div
                            v-if="$v.client.email.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span
                              v-if="!$v.client.email.required"
                              class="text-danger font-size-12 mt-1"
                            >
                              O e-mail é obrigatório.
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="divisor mt-5 mb-3">
                      <span>Endereço</span>
                    </div>

                    <b-form-group label="CEP">
                      <b-form-input
                        v-on:blur="searchCep()"
                        v-model="client.zipcode"
                        type="text"
                        :class="{ 'is-invalid': $v.client.zipcode.$error }"
                        autocomplete="off"
                        v-mask="'#####-###'"
                      ></b-form-input>
                      <div
                        v-if="$v.client.zipcode.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.client.zipcode.required"
                          >O CEP é obrigatório.</span
                        >
                        <span v-if="!$v.client.zipcode.invalid"
                          >O CEP informado é invalido.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group label="Endereço">
                      <b-form-input
                        v-model="client.address"
                        type="text"
                        :class="{ 'is-invalid': $v.client.address.$error }"
                        autocomplete="off"
                      ></b-form-input>
                      <div
                        v-if="$v.client.address.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.client.address.required"
                          >O endereço é obrigatório.</span
                        >
                      </div>
                    </b-form-group>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group label="Número">
                          <b-form-input
                            v-model="client.number"
                            type="text"
                            :class="{ 'is-invalid': $v.client.number.$error }"
                            autocomplete="off"
                          ></b-form-input>
                          <div
                            v-if="$v.client.number.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.number.required"
                              >O número é obrigatório.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group label="Complemento">
                          <b-form-input
                            v-model="client.complement"
                            type="text"
                            :class="{ 'is-invalid': $v.client.complement.$error }"
                            autocomplete="off"
                          ></b-form-input>
                          <div
                            v-if="$v.client.complement.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.complement.required"
                              >O complemento é obrigatório.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <b-form-group label="Bairro">
                      <b-form-input
                        v-model="client.district"
                        type="text"
                        :class="{ 'is-invalid': $v.client.district.$error }"
                        autocomplete="off"
                      ></b-form-input>
                      <div
                        v-if="$v.client.district.$error"
                        class="text-danger font-size-12 mt-1"
                      >
                        <span v-if="!$v.client.district.required"
                          >O bairro é obrigatório.</span
                        >
                      </div>
                    </b-form-group>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group label="Cidade">
                          <b-form-input
                            class="bg-soft-info"
                            v-model="client.city"
                            type="text"
                            :class="{ 'is-invalid': $v.client.city.$error }"
                            autocomplete="off"
                            readonly="readonly"
                          ></b-form-input>
                          <div
                            v-if="$v.client.city.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.city.required"
                              >A cidade é obrigatória.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group label="Estado">
                          <b-form-input
                            class="bg-soft-info"
                            v-model="client.state"
                            type="text"
                            :class="{ 'is-invalid': $v.client.state.$error }"
                            autocomplete="off"
                            readonly="readonly"
                            v-mask="'XX'"
                          ></b-form-input>
                          <div
                            v-if="$v.client.state.$error"
                            class="text-danger font-size-12 mt-1"
                          >
                            <span v-if="!$v.client.state.required"
                              >O estado é obrigatório.</span
                            >
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                  </template>

                  <div class="d-flex justify-content-between mt-5 text-center">
                    <div></div>
                    <button
                      class="btn btn-default btn-lg"
                      v-on:click="nextToFiles()"
                      :disabled="loading.client"
                    >
                      Avançar
                      <b-spinner
                        v-if="loading.client"
                        small
                        class="ml-2 align-middle"
                        variant="white"
                        role="status"
                      ></b-spinner>
                    </button>
                  </div>
                </template>
                <template v-else-if="step == 2">
                  <div class="divisor mb-3 text-left">
                    <span>Dados da Fatura</span>
                  </div>
                  <b-form-group class="mb-5" :label="t('Número da Unidade Consumidora (UC)')">
                    <b-form-input
                      v-model="invoice.uc"
                      type="text"
                      :class="{ 'is-invalid': $v.invoice.uc.$error }"
                    ></b-form-input>
                    <div
                      v-if="$v.invoice.uc.$error"
                      class="text-danger font-size-12 mt-1"
                    >
                      <span v-if="!$v.invoice.uc.required">
                        A unidade consumidora é obrigatória.
                      </span>
                    </div>
                  </b-form-group>

                  <div class="divisor mt-5 mb-3 text-left">
                    <span>Fatura Atualizada</span>
                  </div>

                  <div class="row mb-2">
                    <div class="col-md-12">
                      <h6>Arquivo</h6>
                      <div
                        v-if="files && files[2] && files[2]['all']"
                        class="contract-file d-flex"
                      >
                        <div class="flex-fill">
                          <a
                            class="text-dark"
                            target="_blank"
                            :href="files[2]['all']['url']"
                            >{{ files[2]["all"]["name"] }}</a
                          ><br>
                          <small>{{ files[2]["all"]["date"] }}</small>
                        </div>
                        <div>
                          <button
                            class="btn btn-link m-0 p-0"
                            v-on:click="files[2]['all'] = null"
                          >
                            <i class="bx bx-trash font-size-20 text-danger"></i>
                          </button>
                        </div>
                      </div>
                      <UploadFile
                        v-else
                        :id="this.$route.params.id"
                        type="2"
                        side="all"
                      ></UploadFile>
                    </div>
                  </div>

                  <div class="divisor mt-5 mb-3 text-left">
                    <span>CPF ou CNH</span>
                  </div>

                  <div class="row mb-5">
                    <div class="col-md-6 mb-2">
                      <h6>Frente</h6>
                      <div
                        v-if="files && files[1] && files[1]['front']"
                        class="contract-file d-flex"
                      >
                        <div class="flex-fill">
                          <a
                            class="text-dark"
                            target="_blank"
                            :href="files[1]['front']['url']"
                            >{{ files[1]["front"]["name"] }}</a
                          ><br>
                          <small>{{ files[1]["front"]["date"] }}</small>
                        </div>
                        <div>
                          <button
                            class="btn btn-link m-0 p-0"
                            v-on:click="files[1]['front'] = null"
                          >
                            <i class="bx bx-trash font-size-20 text-danger"></i>
                          </button>
                        </div>
                      </div>
                      <UploadFile
                        v-else
                        :id="this.$route.params.id"
                        type="1"
                        side="front"
                      ></UploadFile>
                    </div>
                    <div class="col-md-6">
                      <h6>Verso</h6>
                      <div
                        v-if="files && files[1] && files[1]['back']"
                        class="contract-file d-flex"
                      >
                        <div class="flex-fill">
                          <a
                            class="text-dark"
                            target="_blank"
                            :href="files[1]['back']['url']"
                            >{{ files[1]["back"]["name"] }}</a
                          ><br>
                          <small>{{ files[1]["back"]["date"] }}</small>
                        </div>
                        <div>
                          <button
                            class="btn btn-link m-0 p-0"
                            v-on:click="files[1]['back'] = null"
                          >
                            <i class="bx bx-trash font-size-20 text-danger"></i>
                          </button>
                        </div>
                      </div>
                      <UploadFile
                        v-else
                        :id="this.$route.params.id"
                        type="1"
                        side="back"
                      ></UploadFile>
                    </div>
                  </div>

                  <template v-if="client.type == 'legal'">
                    <div class="divisor mt-5 mb-3 text-left">
                      <span>Documentos da Empresa</span>
                    </div>

                    <div class="row mb-5">
                      <div class="col-md-6 mb-2">
                        <h6>Contrato Social</h6>
                        <div
                          v-if="files && files[3] && files[3]['all']"
                          class="contract-file d-flex"
                        >
                          <div class="flex-fill">
                            <a
                              class="text-dark"
                              target="_blank"
                              :href="files[3]['all']['url']"
                              >{{ files[3]["all"]["name"] }}</a
                            ><br>
                            <small>{{ files[3]["all"]["date"] }}</small>
                          </div>
                          <div>
                            <button
                              class="btn btn-link m-0 p-0"
                              v-on:click="files[3]['all'] = null"
                            >
                              <i class="bx bx-trash font-size-20 text-danger"></i>
                            </button>
                          </div>
                        </div>
                        <UploadFile
                          v-else
                          :id="this.$route.params.id"
                          type="3"
                          side="all"
                        ></UploadFile>
                      </div>
                      <div class="col-md-6">
                        <h6>Cartão CNPJ</h6>
                        <div
                          v-if="files && files[4] && files[4]['all']"
                          class="contract-file d-flex"
                        >
                          <div class="flex-fill">
                            <a
                              class="text-dark"
                              target="_blank"
                              :href="files[4]['all']['url']"
                              >{{ files[4]["all"]["name"] }}</a
                            ><br>
                            <small>{{ files[4]["all"]["date"] }}</small>
                          </div>
                          <div>
                            <button
                              class="btn btn-link m-0 p-0"
                              v-on:click="files[4]['all'] = null"
                            >
                              <i class="bx bx-trash font-size-20 text-danger"></i>
                            </button>
                          </div>
                        </div>
                        <UploadFile
                          v-else
                          :id="this.$route.params.id"
                          type="4"
                          side="all"
                        ></UploadFile>
                      </div>
                    </div>
                  </template>

                  <div class="d-flex justify-content-between text-center">
                    <button
                      class="btn btn-dark btn-lg"
                      v-on:click="
                        step = 1;
                        toTop();
                      "
                    >
                      Voltar
                    </button>
                    <button
                      class="btn btn-default btn-lg"
                      v-on:click="
                        sendContract();
                        toTop();
                      "
                      :disabled="loading.send"
                    >
                      Enviar
                      <b-spinner
                        v-if="loading.send"
                        small
                        class="ml-2 align-middle"
                        variant="white"
                        role="status"
                      ></b-spinner>
                    </button>
                  </div>
                </template>
              </template>
              <template v-else-if="contract.status == 'send'">
                <b-form-group :label="t('Tipo de Pessoa')">
                  <div v-if="client.type == 'natural'" class="bg-soft-light border rounded p-2">Pessoa Física</div>
                  <div v-if="client.type == 'legal'" class="bg-soft-light border rounded p-2">Pessoa Jurídica</div>
                </b-form-group>

                <template v-if="client.type == 'natural'">
                  <div class="divisor mt-5 mb-3">
                    <span>Dados Pessoais</span>
                  </div>

                  <b-form-group :label="t('Nome Completo')">
                    <div class="bg-soft-light border rounded p-2">{{ client.name }}</div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group :label="t('Data de nascimento')">
                        <div class="bg-soft-light border rounded p-2">{{ client.birthdate }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group :label="t('CPF')">
                        <div class="bg-soft-light border rounded p-2">{{ client.cpf }}</div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group :label="t('RG')">
                        <div class="bg-soft-light border rounded p-2">{{ client.rg_number }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group :label="t('Órgão Emissor')">
                        <div class="bg-soft-light border rounded p-2">{{ client.rg_issuer }}</div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group :label="t('Nacionalidade')">
                        <div class="bg-soft-light border rounded p-2">{{ client.nationality }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group :label="t('Estado Civil')">
                        <div class="bg-soft-light border rounded p-2">{{ client.marital_status }}</div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group :label="t('Celular')">
                        <div class="bg-soft-light border rounded p-2">{{ client.cellphone }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group :label="t('E-mail')">
                        <div class="bg-soft-light border rounded p-2">{{ client.email }}</div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="divisor mt-5 mb-3">
                    <span>Endereço</span>
                  </div>

                  <b-form-group label="CEP">
                    <div class="bg-soft-light border rounded p-2">{{ client.zipcode }}</div>
                  </b-form-group>

                  <b-form-group label="Endereço">
                    <div class="bg-soft-light border rounded p-2">{{ client.address }}</div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group label="Número">
                        <div class="bg-soft-light border rounded p-2">{{ client.number }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group label="Complemento">
                        <div class="bg-soft-light border rounded p-2">{{ client.complement }}</div>
                      </b-form-group>
                    </div>
                  </div>

                  <b-form-group label="Bairro">
                    <div class="bg-soft-light border rounded p-2">{{ client.district }}</div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group label="Cidade">
                        <div class="bg-soft-light border rounded p-2">{{ client.city }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group label="Estado">
                        <div class="bg-soft-light border rounded p-2">{{ client.state }}</div>
                      </b-form-group>
                    </div>
                  </div>
                </template>

                <template v-else-if="client.type == 'legal'">
                  <div class="divisor mt-5 mb-3">
                    <span>Dados da Empresa</span>
                  </div>

                  <b-form-group :label="t('Razão Social')">
                        <div class="bg-soft-light border rounded p-2">{{ client.legal_name }}</div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group :label="t('CNPJ')">
                        <div class="bg-soft-light border rounded p-2">{{ client.legal_number }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group :label="t('Data de Abertura')">
                        <div class="bg-soft-light border rounded p-2">{{ client.legal_opening }}</div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="divisor mt-5 mb-3">
                    <span>Dados do Representante</span>
                  </div>

                  <b-form-group :label="t('Nome Completo')">
                    <div class="bg-soft-light border rounded p-2">{{ client.name }}</div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group :label="t('Data de nascimento')">
                        <div class="bg-soft-light border rounded p-2">{{ client.birthdate }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group :label="t('CPF')">
                        <div class="bg-soft-light border rounded p-2">{{ client.cpf }}</div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group :label="t('RG')">
                        <div class="bg-soft-light border rounded p-2">{{ client.rg_number }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group :label="t('Órgão Emissor')">
                        <div class="bg-soft-light border rounded p-2">{{ client.rg_issuer }}</div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group :label="t('Nacionalidade')">
                        <div class="bg-soft-light border rounded p-2">{{ client.nationality }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group :label="t('Estado Civil')">
                        <div class="bg-soft-light border rounded p-2">{{ client.marital_status }}</div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group :label="t('Celular')">
                        <div class="bg-soft-light border rounded p-2">{{ client.cellphone }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group :label="t('E-mail')">
                        <div class="bg-soft-light border rounded p-2">{{ client.email }}</div>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="divisor mt-5 mb-3">
                    <span>Endereço</span>
                  </div>

                  <b-form-group label="CEP">
                    <div class="bg-soft-light border rounded p-2">{{ client.zipcode }}</div>
                  </b-form-group>

                  <b-form-group label="Endereço">
                    <div class="bg-soft-light border rounded p-2">{{ client.address }}</div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group label="Número">
                        <div class="bg-soft-light border rounded p-2">{{ client.number }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group label="Complemento">
                        <div class="bg-soft-light border rounded p-2">{{ client.complement }}</div>
                      </b-form-group>
                    </div>
                  </div>

                  <b-form-group label="Bairro">
                    <div class="bg-soft-light border rounded p-2">{{ client.district }}</div>
                  </b-form-group>

                  <div class="row">
                    <div class="col-md-6">
                      <b-form-group label="Cidade">
                        <div class="bg-soft-light border rounded p-2">{{ client.city }}</div>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group label="Estado">
                        <div class="bg-soft-light border rounded p-2">{{ client.state }}</div>
                      </b-form-group>
                    </div>
                  </div>
                </template>

                <div class="divisor mt-5 mb-3 text-left">
                  <span>Dados da Fatura</span>
                </div>

                <b-form-group class="mb-5" :label="t('Número da Unidade Consumidora (UC)')">
                  <div class="bg-soft-light border rounded p-2">{{ contract.uc }}</div>
                </b-form-group>

                <div class="divisor mt-5 mb-3 text-left">
                  <span>Fatura Atualizada</span>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <h6>Arquivo</h6>
                    <div
                      v-if="files && files[2] && files[2]['all']"
                      class="contract-file d-flex"
                    >
                      <div class="flex-fill">
                        <a
                          class="text-dark"
                          target="_blank"
                          :href="files[2]['all']['url']"
                          >{{ files[2]["all"]["name"] }}</a
                        ><br>
                        <small>{{ files[2]["all"]["date"] }}</small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="divisor mt-4 mb-3 text-left">
                  <span>CPF ou CNH</span>
                </div>

                <div class="row mb-2">
                  <div class="col-md-6">
                    <h6>Frente</h6>
                    <div
                      v-if="files && files[1] && files[1]['front']"
                      class="contract-file d-flex"
                    >
                      <div class="flex-fill">
                        <a
                          class="text-dark"
                          target="_blank"
                          :href="files[1]['front']['url']"
                          >{{ files[1]["front"]["name"] }}</a
                        ><br>
                        <small>{{ files[1]["front"]["date"] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <h6>Verso</h6>
                    <div
                      v-if="files && files[1] && files[1]['back']"
                      class="contract-file d-flex"
                    >
                      <div class="flex-fill">
                        <a
                          class="text-dark"
                          target="_blank"
                          :href="files[1]['back']['url']"
                          >{{ files[1]["back"]["name"] }}</a
                        ><br>
                        <small>{{ files[1]["back"]["date"] }}</small>
                      </div>
                    </div>
                  </div>
                </div>

                <template v-if="client.type == 'legal'">
                  <div class="divisor mt-4 mb-3 text-left">
                    <span>Documentos da Empresa</span>
                  </div>

                  <div class="row mb-2">
                    <div class="col-md-6">
                      <h6>Contrato Social</h6>
                      <div
                        v-if="files && files[3] && files[3]['all']"
                        class="contract-file d-flex"
                      >
                        <div class="flex-fill">
                          <a
                            class="text-dark"
                            target="_blank"
                            :href="files[3]['all']['url']"
                            >{{ files[3]["all"]["name"] }}</a
                          ><br>
                          <small>{{ files[3]["all"]["date"] }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h6>Cartão CNPJ</h6>
                      <div
                        v-if="files && files[4] && files[4]['all']"
                        class="contract-file d-flex"
                      >
                        <div class="flex-fill">
                          <a
                            class="text-dark"
                            target="_blank"
                            :href="files[4]['all']['url']"
                            >{{ files[4]["all"]["name"] }}</a
                          ><br>
                          <small>{{ files[4]["all"]["date"] }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <div v-else class="p-3 text-center">
              <b-spinner
                small
                class="ml-2 align-middle"
                variant="default"
                role="status"
              ></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.label-description {
  margin: -10px 0 10px 0;
}
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.divisor span {
  position: relative;
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}
.divisor.text-left span {
  margin-left: 15px;
}

.contract-file {
  background: #f1f0ef !important;
  border-radius: 0.5rem !important;
  margin-bottom: 16px;
  padding: 1.7rem 1rem 1.28rem;
  line-height: 14px;
}
.contract-file .flex-fill {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}
</style>

<style>
.filepond--credits {
  display: none;
}
</style>
