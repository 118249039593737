<script>
import appConfig from "../../../src/app.config";

import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";

const FilePond = vueFilePond();

export default {
  props: {
    id: {
      default: "",
    },
    type: {
      default: "",
    },
    side: {
      default: "",
    },
  },
  components: {
    FilePond,
  },
  data() {
    return {
      files: null,
      success: false,

      url: appConfig.apiUrl,
      token: localStorage.token,
    };
  },
  methods: {
    getFiles() {
      console.log("asdasd");
    },
  },
  created() {
    setOptions({
      server: {
        process: {
          headers: {
            "x-auth-token": localStorage.token,
          },
          onload: (response) => {
            console.log(response)
            console.log('12123')
          }
        },
      },
    });
  },
};
</script>

<template>
  <div>
    <file-pond
      name="file"
      ref="file"
      allowRevert="false"
      v-bind:files="files"
      label-idle="Arraste aqui ou escolher arquivo"
      :server="{
        url:
          url +
          'energy/contracts/files/' +
          this.$props.id +
          '/' +
          this.$props.type +
          '/' +
          this.$props.side,
        process: {
          headers: {
            'x-auth-token': this.token,
          },
          onload: () => {
            this.$parent.$parent.getFiles()
          }
        },
      }"
    />
  </div>
</template>

<style scoped>
.report {
  margin-top: -90px;
}
.label {
  line-height: 13px;
}
</style>
